/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-17 19:47:35
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-23 11:51:07
 */
import { createNamespacedHelpers } from 'vuex'
import SectionTitle from './components/sectionTitle'
import TodayData from './components/todayData'
import TotalData from './components/totalData'
import TrendData from './components/trendData'
import { getNormalWeek } from '@/plugins/utils'

const { mapActions } = createNamespacedHelpers('Operate/Overview')

export default {
  name: 'OperateOverview',
  components: {
    SectionTitle,
    TodayData,
    TotalData,
    TrendData
  },
  data () {
    return {
      intervalTimer: null,
      refreshTime: 60000
    }
  },
  async mounted () {
    // 获取总览数据
    await this.getOverviewData()
    // 更新时间
    const [startDate, endDate] = getNormalWeek()
    await this.updateTrendQueryForm({
      _date: [new Date(startDate), new Date(endDate)],
      start_date: startDate,
      end_date: endDate
    })
    // 获取折线图数据
    await this.getTradeData()
    // 定时刷新数据
    this.refreshData()
  },
  beforeDestroy () {
    // 移除定时器
    this.clearInterval()
  },
  methods: {
    ...mapActions([
      'getOverviewData', // 查询总体信息
      'updateTrendQueryForm', // 更新时间
      'getTradeData' // 查询趋势信息
    ]),

    /**
     * 清除定时器
     */
    clearInterval () {
      if (this.intervalTimer) {
        clearTimeout(this.intervalTimer)
        this.intervalTimer = null
      }
    },

    /**
     * 每隔一分钟自动请求数据
     */
    async refreshData () {
      await this.clearInterval()

      this.intervalTimer = setTimeout(async () => {
        // 获取总览数据
        await this.getOverviewData()
        // 获取折线图数据
        await this.getTradeData()
        // 进入下一个循环
        this.refreshData()
      }, this.refreshTime)
    }
  }
}
