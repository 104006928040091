/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-17 19:47:09
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-17 19:48:16
 */
import AppOverview from './Overview.vue'
export default AppOverview
